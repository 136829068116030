import type { CurrencyCode, Scalars, SearchResultPriceFragment } from '#graphql-operations'

export function usePrice() {
  const { n } = useI18n()
  const format = (amount: number, currencyCode: CurrencyCode) => n(amount / 100, 'currency', { currency: currencyCode })

  return (price: Scalars['Money'] | SearchResultPriceFragment, currencyCode: CurrencyCode) => {
    if (typeof price === 'number')
      return format(price, currencyCode)

    if ('value' in price)
      return format(price.value, currencyCode)

    if ('min' in price && 'max' in price)
      return price.min === price.max ? format(price.min, currencyCode) : `${format(price.min, currencyCode)} - ${format(price.max, currencyCode)}`

    console.warn('Invalid price', price)
    return format(0, currencyCode)
  }
}
